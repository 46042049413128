import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "",
  complex: "",
  source: "",
  diseases: [],
  products: [],
  physicalActivity: null,
  menu: {},
  human_params: {
    idClient: null,
    GENDER: null,
    AGE: null,
    HT: null,
    WT: null,
    systolic: null,
    diastolic: null,
  },
  skip_human_params: false,
};

export const testFormSlice = createSlice({
  name: "testForm",
  initialState,
  reducers: {
    setMode: ( state, action ) => {
      state.mode = action.payload;
    },
    setComplex: ( state, action ) => {
      state.complex = action.payload;
    },
    setSource: ( state, action ) => {
      state.source = action.payload;
    },
    setHumanParams: ( state, action ) => {
      state.human_params = action.payload;
    },
    setSkipHumanParams: ( state, action ) => {
      state.skip_human_params = action.payload;
    },
    chooseDiseases: (state, action) => {
      state.diseases = action.payload;
    },
    chooseProducts: (state, action) => {
      state.products = action.payload;
    },
    choosePhysicalActivity: ( state, action ) => {
      state.physicalActivity = action.payload;
    },
    chooseMenu: ( state, action ) => {
      state.menu = action.payload;
    },
    reset: (state) => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setMode,
  setComplex,
  setSource,
  setHumanParams,
  setSkipHumanParams,
  chooseDiseases,
  chooseProducts,
  choosePhysicalActivity,
  chooseMenu,
  reset,
} = testFormSlice.actions;

export default testFormSlice.reducer;
