import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: {},
  complex: {
    client: {},
  },
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setComplex: (state, action) => {
      state.complex = action.payload;
    },
    reset: (state) => ({
      loading: false,
      menu: {},
      complex: {
        client: {},
      },
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setMenu, setComplex, reset } = menuSlice.actions;

export default menuSlice.reducer;
