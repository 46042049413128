import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: {},
  client: {},
  complex: {},
};

export const sportSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setClient: ( state, action ) => {
      state.client = action.payload;
    },
    setComplex: ( state, action ) => {
      state.complex = action.payload;
    },
    reset: (state) => ({
      loading: false,
      menu: {},
      client: {},
      complex: {},
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setMenu, setClient, setComplex, reset } = sportSlice.actions;

export default sportSlice.reducer;
