import { configureStore } from "@reduxjs/toolkit";

import testFormReducer from "./reduxStateSlice/testForm";
import menuReducer from "./reduxStateSlice/menu";
import sportReducer from "./reduxStateSlice/sport";

export default configureStore({
  reducer: {
    testForm: testFormReducer,
    menu: menuReducer,
    sport: sportReducer,
  },
});
