import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import { Provider } from "react-redux";
import store from "../store";

// global styles
import "../assets/styles/app.scss";

// pages
const Stats = loadable( () => import( "../views/containers/Stats" ) );
const ComplexList = loadable( () => import( "../views/containers/ComplexList" ) );
const PersonalArea = loadable(() => import("../views/containers/PersonalArea"));
const SignIn = loadable(() => import("../views/containers/SignIn"));
const DiseasesFailure = loadable(() =>
  import("../views/containers/DiseasesFailure")
);
const ProductsOtherFailure = loadable(() =>
  import("../views/containers/ProductsOtherFailure")
);
const ProductsFailure = loadable(() =>
  import("../views/containers/ProductsFailure")
);
const Recipes = loadable(() => import("../views/containers/Recipes"));
const RecommendedDiet = loadable(() =>
  import("../views/containers/RecommendedDiet")
);
const Email = loadable(() => import("../views/containers/Email"));
const PrintSuccess = loadable(() => import("../views/containers/PrintSuccess"));
const EmailSuccess = loadable(() => import("../views/containers/EmailSuccess"));
const Success = loadable(() => import("../views/containers/Success"));
const PhysicalActivityDetailing = loadable(() =>
  import("../views/containers/PhysicalActivityDetailing")
);
const PhysicalActivity = loadable(() =>
  import("../views/containers/PhysicalActivity")
);
const RecommendedSport = loadable(() =>
  import("../views/containers/RecommendedSport")
);
const Products = loadable(() => import("../views/containers/Products"));
const DiseaseDetailing = loadable(() =>
  import("../views/containers/DiseaseDetailing")
);
const Diseases = loadable(() => import("../views/containers/Diseases"));
const Start = loadable( () => import( "../views/containers/Start" ) );
const StartMode = loadable( () => import( "../views/containers/StartMode" ) );
const StartSport = loadable( () => import( "../views/containers/StartSport" ) );
const HumanParams = loadable(() => import("../views/containers/HumanParams"));
const SportDiseases = loadable(() => import("../views/containers/SportDiseases"));
const Estimate = loadable(() => import("../views/containers/Estimate"));
const EstimateSuccess = loadable(() => import("../views/containers/EstimateSuccess"));

// scroll to top
const ScrollToTop = loadable(() => import("../components/ScrollToTop"));

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/stats/complex">
            <ComplexList />
          </Route>
          <Route path="/stats">
            <Stats />
          </Route>
          <Route path="/personal_area">
            <PersonalArea />
          </Route>
          <Route path="/products_other_failure">
            <ProductsOtherFailure />
          </Route>
          <Route path="/products_failure">
            <ProductsFailure />
          </Route>
          <Route path="/diseases_failure">
            <DiseasesFailure />
          </Route>
          <Route path="/recipes">
            <Recipes />
          </Route>
          <Route path="/recommended_diet">
            <RecommendedDiet />
          </Route>
          <Route path="/email">
            <Email />
          </Route>
          <Route path="/print_success">
            <PrintSuccess />
          </Route>
          <Route path="/email_success">
            <EmailSuccess />
          </Route>
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/physical_activity/:id">
            <PhysicalActivityDetailing />
          </Route>
          <Route path="/physical_activity">
            <PhysicalActivity />
          </Route>
          <Route path="/recommended_sport">
            <RecommendedSport />
          </Route>
          <Route path="/products">
            <Products />
          </Route>
          <Route path="/diseases/:id">
            <DiseaseDetailing />
          </Route>
          <Route path="/diseases">
            <Diseases />
          </Route>
          <Route path="/start">
            <Start />
          </Route>
          <Route path="/start_mode">
            <StartMode />
          </Route>
          <Route path="/start_sport">
            <StartSport />
          </Route>
          <Route path="/human_params">
            <HumanParams />
          </Route>
          <Route path="/sport_diseases">
            <SportDiseases />
          </Route>
          <Route path="/estimate">
            <Estimate />
          </Route>
          <Route path="/estimate_success">
            <EstimateSuccess />
          </Route>
          <Route exact path="/">
            <SignIn />
          </Route>Estimate
        </Switch>
      </Router>
    </Provider>
  );
}
